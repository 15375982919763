import { forwardRef } from 'react';
import styles from './InputRow.module.css';

type Props = {
  label: string;
  children: React.ReactNode;
  errorText?: string;
  hintText?: string;
};
const InputRow = forwardRef<HTMLDivElement, Props>(
  ({ label, children, errorText, hintText }, ref) => (
    <div className={styles.container} ref={ref}>
      <label htmlFor={label} className={styles.label}>
        {label}
      </label>
      <div className={styles.row}>{children}</div>
      {errorText ? <span className={styles.errorText}>{errorText}</span> : null}
      {hintText ? <span className={styles.hintText}>{hintText}</span> : null}
    </div>
  )
);

export default InputRow;
