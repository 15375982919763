import { useContext, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { v4 } from 'uuid';
import Button from '../../../components/Button/Button';
import FormActions from '../../../components/Form/FormActions';
import Input from '../../../components/Form/Input';
import InputRow from '../../../components/Form/InputRow';
import useAppState from '../../../hooks/useAppState/useAppState';
import AccountContext from '../AccountContext';
import styles from './VerifyModal.module.css';

type FormValues = {
  verificationCode: string;
};

export default function MobileVerification({
  setMobileVerified,
}: {
  setMobileVerified: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { currentUser, axios, checkAuth } = useAppState();
  const { user } = useContext(AccountContext);
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);
  const [verifying, setVerifying] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  const verifyMobile = useMutation(
    async () => {
      await axios.put(`/v1/users/${user?.id}/phoneverified`, {
        tid: v4(),
      });
    },
    {
      onSuccess: () => {
        toast.success('Your mobile number has been verified.');
        setMobileVerified(true);
      },
    }
  );

  async function sendVerificationCode() {
    if (currentUser) {
      setSending(true);
      currentUser.getAttributeVerificationCode('phone_number', {
        onSuccess: () => {
          setSent(true);
        },
        onFailure: () => {
          toast.error('Something went wrong. Please contact support.');
        },
      });
    }
  }

  const verify: SubmitHandler<FormValues> = async (data) => {
    setVerifying(true);
    currentUser?.verifyAttribute('phone_number', data.verificationCode, {
      onSuccess: () => {
        checkAuth();
        verifyMobile.mutate();
      },
      onFailure: () => {
        toast.error('Something went wrong. Please contact support.');
      },
    });
  };

  return (
    <div className={styles.container}>
      {!sent ? (
        <>
          <p>
            Before we can link your account to a bookmaker, we need to verify
            your phone number.
          </p>
          <p>
            A message will be sent to {currentUser?.attributes.phone_number}{' '}
            with a 6 digit verification code.
          </p>
          <Button onClick={sendVerificationCode} disabled={sending}>
            Send verification code
          </Button>
        </>
      ) : (
        <form onSubmit={handleSubmit(verify)}>
          <p>
            Please enter the 6 digit verification code sent to{' '}
            {currentUser?.attributes.phone_number}.
          </p>
          <InputRow
            label="Verification Code"
            errorText={errors.verificationCode?.message}
          >
            <Input
              id="Verification Code"
              {...register('verificationCode', { required: 'Required' })}
            />
          </InputRow>
          <FormActions>
            <Button type="submit" disabled={verifying}>
              Verify Now
            </Button>
          </FormActions>
        </form>
      )}
    </div>
  );
}
