import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import useClickOutside from '../../hooks/useClickOutside';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import styles from './Modal.module.css';

export default function Modal({
  title,
  onDismiss,
  children,
}: {
  title: string;
  onDismiss?: () => void;
  children: React.ReactNode;
}) {
  const modalRef = useRef<HTMLDivElement | null>(null);
  const [portalOpened, setPortalOpened] = useState(false);
  useClickOutside(modalRef, onDismiss || (() => {}), portalOpened);

  useEffect(() => {
    setPortalOpened(true);
    document.body.classList.add('hide-overflow');

    return () => {
      document.body.classList.remove('hide-overflow');
    };
  }, []);

  return createPortal(
    <div className={styles.container}>
      <div className={styles.modal} ref={modalRef}>
        <div className={styles.modalHeader}>
          <span className={styles.title}>{title}</span>
          {onDismiss ? (
            <Button theme="none" onClick={onDismiss}>
              <Icon name="cross" />
            </Button>
          ) : null}
        </div>
        {children}
      </div>
    </div>,
    document.body
  );
}
