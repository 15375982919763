import Auth from '@aws-amplify/auth';
import QueryString from 'qs';
import { useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from '../../../components/Button/Button';
import Container from '../../../components/Container/Container';
import useAppState from '../../../hooks/useAppState/useAppState';
import { ReactComponent as Logo } from './bookies-card-light.svg';
import styles from './Layout.module.css';

export default function Layout({ children }: { children: React.ReactNode }) {
  const history = useHistory();
  const location = useLocation();
  const { currentUser, setCurrentUser, setShowSettings } = useAppState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  async function logout() {
    await Auth.signOut();
    setCurrentUser(undefined);
    toast.success('You have logged out.');
    history.push('/');
  }

  function render() {
    if (process.env.REACT_APP_ACCOUNT_ENABLED === 'true') {
      return (
        <>
          <Button
            theme="secondary"
            onClick={() =>
              history.push({
                search: QueryString.stringify({ action: 'signup' }),
              })
            }
          >
            Sign up
          </Button>
          <Button
            onClick={() =>
              history.push({
                search: QueryString.stringify({ action: 'login' }),
              })
            }
          >
            Login
          </Button>
        </>
      );
    }

    return (
      <Button theme="secondary" disabled>
        Coming Soon
      </Button>
    );
  }

  return (
    <>
      <header className={styles.header}>
        <div className={styles.logoContainer}>
          <Link to="/">
            <Logo className={styles.logo} />
          </Link>
        </div>
        <div className={styles.actionsContainer}>
          {currentUser ? (
            <>
              <Button onClick={() => setShowSettings(true)}>Settings</Button>
              <Button onClick={logout}>Logout</Button>
            </>
          ) : (
            render()
          )}
        </div>
      </header>
      {children}
      <footer className={styles.footer}>
        <Container>
          <div className={styles.footerContent}>
            <Link to="/terms-and-conditions">Terms &amp; Conditions</Link>
            <Link to="/privacy-policy">Privacy &amp; Policy</Link>
            <a href="/docs/20211021 - Bookies Card - FSG.pdf" target="_blank">
              Financial Services Guide
            </a>
            <a
              href="/docs/20211021 - Bookies Card Mastercard - PDS.pdf"
              target="_blank"
            >
              Product Disclosure Statement
            </a>
            <p>
              Contact us at{' '}
              <a href="mailto:support@bookiescard.com">
                support@bookiescard.com
              </a>{' '}
              <br />
            </p>
            <p>
              The Bookies Card is issued by EML Payment Solutions Limited ABN 30
              131 436 532 AFSL 404131 pursuant to license by Mastercard
              Asia/Pacific Pte. Ltd. Mastercard and the circles design are
              registered trademarks of Mastercard International Incorporated.
              Refer to the PDS to make sure this product is fit for you. The
              Target Market Determination for this product can be found{' '}
              <Link to="/eml-disclaimer">here</Link>.
            </p>
            <span>
              &copy; Copyright BetMakers Technology Group{' '}
              {new Date().getFullYear()}
            </span>
          </div>
        </Container>
      </footer>
    </>
  );
}
