import { BookieDetail } from '../types/models';

const RWAT = {
  name: 'Rob Waterhouse',
  logo: '/bookies/rwat.svg',
  appstore: 'https://apps.apple.com/au/app/rob-waterhouse/id1567335064',
  googleplay:
    'https://play.google.com/store/apps/details?id=com.betmakers.robwaterhouse',
};
const RB = {
  name: 'Real Bookie',
  logo: '/bookies/realbookie.svg',
  appstore: 'https://apps.apple.com/au/app/realbookie/id1563372114',
  googleplay:
    'https://play.google.com/store/apps/details?id=com.betmakers.realbookie',
};

const bookieDetail: Record<string, BookieDetail> = {
  // UAT
  'b3c27b0f-e288-4473-b0c7-0a2fda022372': RB,
  // PROD
  '2df782c1-8389-41ac-b58a-1987b5119249': RB,
  // UAT
  '2f98efd6-15a9-47ef-a5fe-d8e214d33010': {
    name: 'Bet Deluxe',
    logo: '/bookies/betdeluxe.svg',
    appstore: 'https://apps.apple.com/au/app/betdeluxe/id1560981722',
    googleplay:
      'https://play.google.com/store/apps/details?id=com.betmakers.betdeluxe',
  },
  // UAT
  '80f91e92-760b-466e-aa60-50cd1264289d': {
    name: 'TexBet',
    logo: '/bookies/texbet.svg',
    appstore: 'https://apps.apple.com/au/app/texbet/id1559139811',
    googleplay:
      'https://play.google.com/store/apps/details?id=com.betmakers.texbet',
  },
  // UAT
  'b00c4d37-7694-44d9-8903-147f4bb06a2e': RWAT,
  // PROD
  '2b259968-6f2d-40f3-912b-dc71cb53e901': RWAT,
};

export default bookieDetail;
