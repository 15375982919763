import Auth from '@aws-amplify/auth';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from '../../components/Button/Button';
import Card from '../../components/Card/Card';
import useAppState from '../../hooks/useAppState/useAppState';
import {
  BookieCard,
  User,
  UserBookie,
  UserBookieBalance,
} from '../../types/models';
import styles from './Account.module.css';
import AccountContext from './AccountContext';
import ActivateCardModal from './ActivateCardModal/ActivateCardModal';
import AvailableBookmakers from './AvailableBookmakers/AvailableBookmakers';
import MyBookmakers from './MyBookmakers/MyBookmakers';
import RequestCardModal from './RequestCardModal/RequestCardModal';
import SettingsModal from './SettingsModal/SettingsModal';
import Summary from './Summary/Summary';
import Transactions from './Transactions/Transactions';
import Transfer from './Transfer/Transfer';
import VerifyModal from './VerifyModal/VerifyModal';

export default function Account() {
  const history = useHistory();
  const { currentUser, axios, showSettings } = useAppState();
  const queryClient = useQueryClient();
  const [showRequestCard, setShowRequestCard] = useState(false);
  const [showActivateCard, setShowActivateCard] = useState(false);
  const [showVerify, setShowVerify] = useState(false);
  const [selectedTab, setSelectedTab] = useState<'bookmakers' | 'transactions'>(
    'bookmakers'
  );
  const cards = useQuery<unknown, unknown, BookieCard>('Cards', async () => {
    const response = await axios.get<BookieCard[]>('/v1/cards');
    return response.data.find((card) =>
      ['active', 'pre_active'].includes(card.status.toLowerCase())
    );
  });
  const myBookies = useQuery<UserBookie[]>('UserBookies', async () => {
    const response = await axios.get<UserBookie[]>('/v1/userbookies');
    return response.data;
  });
  const user = useQuery<User>(
    'User',
    async () => {
      const response = await axios.get(
        `/v1/users/${currentUser?.getUsername()}`
      );
      return response.data;
    },
    {
      onSuccess: (data) => {
        setShowVerify(data.kycStatus.toLowerCase() !== 'verified');
      },
    }
  );
  const balance = useQuery<UserBookieBalance[]>('BookiesBalance', async () => {
    const response = await axios.get('/v1/userbookies/balance');
    return response.data;
  });

  useEffect(() => {
    let ws: WebSocket;

    const eventListener = (event: MessageEvent) => {
      try {
        const data = JSON.parse(event.data);
        toast[data.status === 'success' ? 'success' : 'error'](data.message);
        queryClient.refetchQueries();
      } catch (error) {
        // Ignore
      }
    };

    async function init() {
      const session = await Auth.currentSession();
      const idToken = session.getIdToken();
      ws = new WebSocket(
        `${process.env.REACT_APP_SOCKET_URL}?token=${idToken.getJwtToken()}`
      );

      ws.addEventListener('message', eventListener);
    }

    init();

    return () => {
      ws.removeEventListener('message', eventListener);
    };
  }, [queryClient]);

  useEffect(() => {
    if (!currentUser) {
      history.push('/');
    }
  }, [currentUser, history]);

  const verified = user.data?.kycStatus.toLowerCase() === 'verified';

  return (
    <AccountContext.Provider
      value={{
        card: cards.data,
        myBookies: myBookies.data || [],
        user: user.data,
        verified,
        balance: balance.data || [],
      }}
    >
      <>
        <div className={styles.container}>
          <div className={styles.main}>
            <Summary />
            <div className={styles.tabs}>
              <button
                type="button"
                className={clsx(styles.tab, {
                  [styles.selected]: selectedTab === 'bookmakers',
                })}
                onClick={() => setSelectedTab('bookmakers')}
              >
                Bookmakers
              </button>
              <button
                type="button"
                className={clsx(styles.tab, {
                  [styles.selected]: selectedTab === 'transactions',
                })}
                onClick={() => setSelectedTab('transactions')}
                disabled={!cards.data}
              >
                Transactions
              </button>
            </div>
            {selectedTab === 'transactions' ? (
              <Transactions />
            ) : (
              <>
                <MyBookmakers />
                <AvailableBookmakers onVerify={() => setShowVerify(true)} />
              </>
            )}
          </div>
          <div className={styles.secondary}>
            {user.data?.orderStatus ? (
              <div className={styles.margin}>
                <Card>
                  <div className={styles.requestCard}>
                    <div className={styles.imgContainer}>
                      <img
                        src="/bookiescard-straight.png"
                        alt="Card"
                        className={styles.img}
                      />
                    </div>
                    <span className={styles.title}>
                      Request your Bookies Card
                    </span>
                    <Button onClick={() => setShowRequestCard(true)}>
                      Request Card
                    </Button>
                  </div>
                </Card>
              </div>
            ) : null}
            {cards.data?.status.toLowerCase() === 'pre_active' ? (
              <div className={styles.margin}>
                <Card>
                  <div className={styles.requestCard}>
                    <div className={styles.imgContainer}>
                      <img
                        src="/bookiescard-straight.png"
                        alt="Card"
                        className={styles.img}
                      />
                    </div>
                    <span className={styles.title}>
                      Activate your Bookies Card
                    </span>
                    <Button onClick={() => setShowActivateCard(true)}>
                      Activate Card
                    </Button>
                  </div>
                </Card>
              </div>
            ) : null}
            {!myBookies.isLoading ? <Transfer /> : null}
          </div>
        </div>
        {showActivateCard ? (
          <ActivateCardModal onDismiss={() => setShowActivateCard(false)} />
        ) : null}
        {showRequestCard ? (
          <RequestCardModal onDismiss={() => setShowRequestCard(false)} />
        ) : null}
        {showSettings ? (
          <SettingsModal
            onRequestCard={() => setShowRequestCard(true)}
            onVerify={() => setShowVerify(true)}
          />
        ) : null}
        {showVerify ? (
          <VerifyModal onDismiss={() => setShowVerify(false)} />
        ) : null}
      </>
    </AccountContext.Provider>
  );
}
