import dayjs from 'dayjs';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import Box from '../../../components/Box/Box';
import useAppState from '../../../hooks/useAppState/useAppState';
import { Transaction } from '../../../types/models';
import format from '../../../utils/format';
import AccountContext from '../AccountContext';
import styles from './Transactions.module.css';

export default function Transactions() {
  const { card } = useContext(AccountContext);
  const { axios } = useAppState();

  const transactions = useQuery<Transaction[]>('Transactions', async () => {
    const response = await axios.get(`/v1/cards/${card?.id}/transactions`);
    return response.data;
  });

  return (
    <Box>
      <h2>Transactions</h2>
      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Date</th>
              <th>Time</th>
              <th>Description</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {transactions.data?.map((transaction) => (
              <tr key={transaction.id}>
                <td>{dayjs(transaction.timestamp).format('YYYY-MM-DD')}</td>
                <td>{dayjs(transaction.timestamp).format('h:mma')}</td>
                <td>{transaction.description}</td>
                <td>{format.currency(transaction.amount)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Box>
  );
}
