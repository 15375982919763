import Container from '../../components/Container/Container';
import styles from './TermsAndConditions.module.css';

export default function TermsAndConditions() {
  return (
    <div className={styles.container}>
      <Container>
        <h2>Bookies Card Pty Ltd - Terms &amp; Conditions</h2>
        <hr />
        <p>
          This website (<strong>Site</strong>) is operated by Bookies Card Pty
          Ltd (<strong>we</strong>, <strong>our</strong> or <strong>us</strong>
          ). It is available at [www.bookiescard.com.au] and may be available
          through other addresses or channels.
        </p>
        <p>
          Consent: By accessing and/or using our Site, you agree to these terms
          of use (<strong>Terms</strong>) and our Privacy Policy available at
          [insert link to privacy policy]. You are responsible for ensuring that
          all persons who access our Site through your internet connection are
          aware of the Terms and comply with them.
        </p>
        <p>
          Please read these Terms carefully and immediately cease using our Site
          if you do not agree with them.
        </p>
        <p>
          Variations: We may, at any time and at our discretion, vary these
          Terms by publishing the varied terms on our Site. We recommend you
          check our Site regularly to ensure you are aware of our current terms.
          Materials and information on this Site (Content) are subject to change
          without notice. We do not undertake to keep our Site up-to-date and we
          are not liable if any Content is inaccurate or out-of-date. Licence to
          use our Site: We grant you a non-exclusive, royalty-free, revocable,
          worldwide, non-transferable licence to use our Site in accordance with
          these Terms. All other uses are prohibited without our prior written
          consent.
        </p>
        <p>
          Account details: If you are provided with a user identification code,
          password or any other information as part of our security procedures,
          you must treat that information as confidential and not disclose it to
          any other person.
        </p>
        <p>
          Prohibited conduct: You must not do or attempt to do anything: that is
          unlawful; prohibited by any laws applicable to our Site; which we
          would consider inappropriate; or which might bring us or our Site into
          disrepute, including (without limitation):
        </p>
        <ol type="a">
          <li>
            anything that would constitute a breach of an individual’s privacy
            (including uploading private or personal information without an
            individual’s consent) or any other legal rights;
          </li>
          <li>
            using our Site to defame, harass, threaten, menace or offend any
            person;
          </li>
          <li>interfering with any user using our Site;</li>
          <li>
            tampering with or modifying our Site, knowingly transmitting viruses
            or other disabling features, or damaging or interfering with our
            Site, including (without limitation) using trojan horses, denial of
            service attacks, viruses, piracy or programming that may damage or
            interfere with our Site;
          </li>
          <li>
            impersonating or attempting to impersonate us, any of our employees,
            another user or any other person or entity;
          </li>
          <li>
            using our Site to send unsolicited email messages or to post content
            that is offensive, defamatory, pornographic or in any way contrary
            to our standards that might exist from time to time; or
          </li>
          <li>
            facilitating or assisting a third party to do any of the above acts.
          </li>
        </ol>
        <p>
          Exclusion of competitors: You are prohibited from using our Site,
          including the Content, in any way that competes with our business.
        </p>
        <p>
          No commercial use: Our Site is for your personal, non-commercial use
          only. You must not use our Site, or any of the Content, for commercial
          purposes, including any advertising or advertising revenue generation
          activity on your own website or any other platform, without obtaining
          a licence to do so from us.
        </p>
        <p>
          Information: The Content is not comprehensive and is for general
          information purposes only. It does not take into account your specific
          needs, objectives or circumstances, and it is not advice. While we use
          reasonable attempts to ensure the accuracy and completeness of the
          Content, we make no representation or warranty in relation to it, to
          the maximum extent permitted by law.
        </p>
        <p>
          Intellectual Property rights: Unless otherwise indicated, we own or
          license all rights, title, and interest (including intellectual
          property rights) in our Site and all of the Content. Your use of our
          Site and your use of and access to any Content does not grant or
          transfer to you any rights, title or interest in relation to our Site
          or the Content. You must not:
        </p>
        <ol type="a">
          <li>copy or use, in whole or in part, any Content;</li>
          <li>
            reproduce, retransmit, distribute, disseminate, sell, publish,
            broadcast or circulate any Content to any third party; or
          </li>
          <li>
            breach any intellectual property rights connected with our Site or
            the Content, including (without limitation) altering or modifying
            any of the Content, causing any of the Content to be framed or
            embedded in another website or platform, or creating derivative
            works from the Content.
          </li>
        </ol>
        <p>
          User Content: You may be permitted to post, upload, publish, submit or
          transmit relevant information and content (
          <strong>User Content</strong>) on our Site. By making available any
          User Content on or through our Site, you grant to us and our
          affiliates and service providers, and each of their and our respective
          licensees, successors and assigns a worldwide, irrevocable, perpetual,
          non-exclusive, transferable, royalty-free licence to use the User
          Content, with the right to use, view, copy, adapt, modify, distribute,
          license, sell, transfer, communicate, publicly display, publicly
          perform, transmit, stream, broadcast, access, or otherwise exploit
          such User Content for any purpose.
        </p>
        <p>
          You agree that you are solely responsible for all User Content that
          you make available on or through our Site. You represent and warrant
          that:
        </p>
        <ol type="a">
          <li>
            you are either the sole and exclusive owner of all User Content or
            you have all rights, licences, consents and releases that are
            necessary to grant to us the rights in such User Content (as
            contemplated by these Terms); and
          </li>
          <li>
            neither the User Content nor the posting, uploading, publication,
            submission or transmission of the User Content or our use of the
            User Content will infringe, misappropriate or violate a third
            party’s intellectual property rights, or rights of publicity or
            privacy, or result in the violation of any applicable law or
            regulation.
          </li>
        </ol>
        <p>
          We do not endorse or approve, and are not responsible for, any User
          Content. We may, at any time (at our sole discretion), remove any User
          Content.
        </p>
        <p>
          We have the right to disclose your identity to any third party who
          claims that your User Content constitutes a violation of their rights.
        </p>
        <p>
          Third party sites: Our Site may contain links to websites operated by
          third parties. Unless expressly stated otherwise, we do not control,
          endorse or approve, and are not responsible for, the content on those
          websites. You access those websites at your own risk and should make
          your own investigations with respect to the suitability of those
          websites, including by reviewing their terms and conditions of use and
          privacy policy.
        </p>
        <p>
          Discontinuance: We may, at any time and without notice to you, suspend
          or discontinue our Site, in whole or in part. We may also exclude any
          person from using our Site, at any time and at our sole discretion. We
          are not responsible for any Liability you may suffer arising from or
          in connection with any such discontinuance or exclusion. Warranties
          and disclaimers: To the maximum extent permitted by law, we make no
          representations or warranties about our Site or the Content, including
          (without limitation) that:
        </p>
        <ol type="a">
          <li>
            they are complete, accurate, reliable, up-to-date and suitable for
            any particular purpose;
          </li>
          <li>
            access will be uninterrupted, error-free or free from bugs or
            viruses or any other type of malicious code or software; or
          </li>
          <li>our Site will be secure.</li>
        </ol>
        <p>
          You read, use and act on our Site and the Content at your own risk.
        </p>
        <p>
          Limitation of liability: To the maximum extent permitted by law, we
          and our affiliates, and our and their licensors, service providers,
          employees, agents, officers and directors are not responsible for any
          loss, damage or expense, howsoever arising, whether direct or indirect
          and/or whether present, unascertained, future or contingent (
          <strong>Liability</strong>) suffered by you or any third party,
          arising from or in connection with your use of our Site and/or the
          Content and/or any inaccessibility of, interruption to or outage of
          our Site and/or any loss or corruption of data and/or the fact that
          the Content is incorrect, incomplete or out-of-date.
        </p>
        <p>
          Nothing in these Terms affects any liability which cannot be excluded
          or limited under applicable law. We do not exclude or limit in any way
          our liability to you where it would be unlawful to do so. Where our
          liability cannot be excluded, we limit our liability to the fullest
          extent permitted by law.
        </p>
        <p>
          Indemnity: To the maximum extent permitted by law, you must indemnify
          and hold us, our affiliates, licensors and service providers, and each
          of our and their respective officers, directors, employees,
          contractors, agents, licensors, suppliers, successors and assigns
          harmless, against any Liability suffered or incurred arising from or
          in connection with your use of our Site or any breach of these Terms
          or any applicable laws by you. This indemnity is a continuing
          obligation, independent from the other obligations under these Terms,
          and continues after these Terms end. It is not necessary for us to
          suffer or incur any Liability before enforcing a right of indemnity
          under these Terms.
        </p>
        <p>
          Termination: These Terms are effective until terminated by us, which
          we may do at any time and without notice to you. In the event of
          termination, all restrictions imposed on you by these Terms and
          limitations of liability set out in these Terms will survive.
        </p>
        <p>
          Disputes: In the event of any dispute arising from, or in connection
          with, these Terms (<strong>Dispute</strong>), the party claiming there
          is a Dispute must give written notice to the other party setting out
          the details of the Dispute and proposing a resolution. Within 7 days
          after receiving the notice, the parties must, by their senior
          executives or senior managers (who have the authority to reach a
          resolution on behalf of the party), meet at least once to attempt to
          resolve the Dispute or agree on the method of resolving the Dispute by
          other means, in good faith. All aspects of every such conference,
          except the fact of the occurrence of the conference, will be on a
          without prejudice basis. If the parties do not resolve the Dispute, or
          (if the Dispute is not resolved) agree on an alternate method to
          resolve the Dispute, within 21 days after receipt of the notice, the
          Dispute may be referred by either party (by notice in writing to the
          other party) to litigation.
        </p>
        <p>
          Severance: If a provision of these Terms is held to be void, invalid,
          illegal or unenforceable, that provision must be read down as narrowly
          as necessary to allow it to be valid or enforceable. If it is not
          possible to read down a provision (in whole or in part), that
          provision (or that part of that provision) is severed from these Terms
          without affecting the validity or enforceability of the remainder of
          that provision or the other provisions in these Terms.
        </p>
        <p>
          Jurisdiction: Your use of our Site and these Terms are governed by the
          laws of New South Wales, Australia. You irrevocably and
          unconditionally submit to the exclusive jurisdiction of the courts
          operating in New South Wales, Australia and any courts entitled to
          hear appeals from those courts and waive any right to object to
          proceedings being brought in those courts.
        </p>
        <p>
          Our Site is directed to users who are at least 18 years old and may be
          accessed throughout Australia and overseas. We make no representation
          that our Site complies with the laws (including intellectual property
          laws) of any country outside Australia. If you access our Site from
          outside Australia, you do so at your own risk and are responsible for
          complying with the laws of the jurisdiction where you access our Site.
        </p>
        <p>For any questions and notices, please contact us at:</p>
        <p>
          Contact us at{' '}
          <a href="mailto:support@bookiescard.com">support@bookiescard.com</a>{' '}
        </p>
        <p>Last update: 12th October 2021</p>
      </Container>
    </div>
  );
}
