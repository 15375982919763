import { useContext } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { v4 } from 'uuid';
import Button from '../../../components/Button/Button';
import FormActions from '../../../components/Form/FormActions';
import Input from '../../../components/Form/Input';
import InputRow from '../../../components/Form/InputRow';
import Select from '../../../components/Form/Select';
import useAppState from '../../../hooks/useAppState/useAppState';
import { UserBookie } from '../../../types/models';
import bookieDetail from '../../../utils/bookieDetail';
import format from '../../../utils/format';
import AccountContext from '../AccountContext';
import styles from './Transfer.module.css';

type FormValues = {
  from: string;
  amount: string;
};

export default function Withdraw() {
  const queryClient = useQueryClient();
  const { myBookies, balance } = useContext(AccountContext);
  const { axios } = useAppState();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<FormValues>();
  const watchValue = watch();

  const transferMutation = useMutation<
    unknown,
    {
      response: {
        data: {
          error: {
            errorNumber: number;
            message: string;
          };
        };
      };
    },
    FormValues
  >(
    async (data) => {
      const bookie = JSON.parse(data.from);

      await axios.post(`/v1/userbookies/${bookie.id}/transfer`, {
        tid: v4(),
        action: 'withdraw-to-bc',
        amount: +data.amount,
      });
    },
    {
      onSuccess: () => {
        toast.success('Your withdrawal was successful.');
      },
      onSettled: () => {
        setTimeout(() => {
          queryClient.refetchQueries('Cards');
          queryClient.refetchQueries('BookiesBalance');
        }, 1000);
      },
      onError: (error) => {
        toast.error('Something went wrong with your withdrawal.');
      },
    }
  );

  const transfer: SubmitHandler<FormValues> = async (data) => {
    await transferMutation.mutate(data);
  };

  const bookie: UserBookie = JSON.parse(watchValue.from || '{}');

  return (
    <form className={styles.content} onSubmit={handleSubmit(transfer)}>
      <InputRow label="From" errorText={errors.from?.message}>
        <Select
          id="From"
          options={[
            {
              label: 'Select a bookie',
              value: '',
            },
            ...myBookies
              .filter((userBookie) => userBookie.status === 'active')
              .map((userBookie) => ({
                label: `${
                  bookieDetail[userBookie.bookieId].name
                } (${format.currency(
                  balance?.find((b) => userBookie.bookieId === b.bookieId)
                    ?.withdrawableBalance
                )})`,
                value: JSON.stringify(userBookie),
              })),
          ]}
          {...register('from', { required: 'Required' })}
        />
      </InputRow>
      <InputRow label="Amount" errorText={errors.amount?.message}>
        <Input
          type="number"
          min={0}
          {...register('amount', {
            required: 'Required',
            min: {
              value: 1,
              message: `Minimum withdrawal amount is ${format.currency(1)}.`,
            },
            max: {
              value:
                balance?.find((b) => b.bookieId === bookie.bookieId)
                  ?.withdrawableBalance || 0,
              message: `You cannot exceed your withdrawable balance (${format.currency(
                balance?.find((b) => b.bookieId === bookie.bookieId)
                  ?.withdrawableBalance || 0
              )}).`,
            },
          })}
        />
      </InputRow>
      <FormActions>
        <Button type="submit" disabled={transferMutation.isLoading}>
          {watchValue.from
            ? `Withdraw from ${
                bookieDetail?.[bookie.bookieId]?.name
              } to Bookies Card`
            : 'Withdraw'}
        </Button>
      </FormActions>
    </form>
  );
}
