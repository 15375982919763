import { useContext } from 'react';
import Box from '../../../components/Box/Box';
import format from '../../../utils/format';
import AccountContext from '../AccountContext';
import styles from './Summary.module.css';

export default function Summary() {
  const { card, balance } = useContext(AccountContext);

  const availableCardBalance = card?.balance || 0;
  const bookmakersBalance = balance?.reduce(
    (total, value) => total + value.balance,
    0
  );
  const availableBookmakersBalance = balance?.reduce(
    (total, value) => total + value.withdrawableBalance,
    0
  );

  return (
    <Box>
      <h2>Summary</h2>
      <div className={styles.content}>
        <div className={styles.imgContainer}>
          <img
            src="/bookiescard-straight.png"
            alt="Card"
            className={styles.img}
          />
        </div>
        <div className={styles.row}>
          <div className={styles.flex}>
            <div className={styles.section}>
              <span className={styles.title}>Bookies Card Balance</span>
              <span className={styles.value}>
                {format.currency(availableCardBalance)}
              </span>
            </div>

            <div className={styles.section}>
              <span className={styles.title}>Available</span>
              <span className={styles.value}>
                {format.currency(availableCardBalance)}
              </span>
            </div>
          </div>
          <div className={styles.flex}>
            <div className={styles.section}>
              <span className={styles.title}>Bookmakers Balance</span>
              <span className={styles.value}>
                {format.currency(bookmakersBalance)}
              </span>
            </div>
            <div className={styles.section}>
              <span className={styles.title}>Available</span>
              <span className={styles.value}>
                {format.currency(availableBookmakersBalance)}
              </span>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
}
