import clsx from 'clsx';
import Icon from '../Icon/Icon';
import styles from './AlertBox.module.css';

type Props = {
  type: 'error' | 'warning' | 'success';
  message: string;
  className?: string;
};

export default function AlertBox({ type, message, className }: Props) {
  return (
    <div className={clsx(styles.alert, styles[type], className)}>
      <Icon
        name={type === 'success' ? 'checkmark' : 'exclamationCircle'}
        className={styles.icon}
      />
      <span className={styles.message}>{message}</span>
    </div>
  );
}
