import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { v4 } from 'uuid';
import AlertBox from '../../../../components/AlertBox/AlertBox';
import Button from '../../../../components/Button/Button';
import FormActions from '../../../../components/Form/FormActions';
import Input from '../../../../components/Form/Input';
import InputRow from '../../../../components/Form/InputRow';
import Modal from '../../../../components/Modal/Modal';
import useAppState from '../../../../hooks/useAppState/useAppState';
import { BookieDetail } from '../../../../types/models';
import styles from './BookmakerSignup.module.css';

type Props = {
  bookieId: string;
  bookieDetail: BookieDetail;
  onDismiss: () => void;
};

type FormValues = {
  preferredPassword: string;
};

export default function BookmakerSignup({
  bookieId,
  bookieDetail,
  onDismiss,
}: Props) {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>();
  const { axios } = useAppState();
  const [errorMessage, setErrorMessage] = useState('');
  const signupBookie = useMutation<unknown, unknown, FormValues>(
    async (data) => {
      await axios.post('/v1/userbookies', {
        action: 'signup',
        bookieId,
        data,
        tid: v4(),
      });
    },
    {
      onSuccess: () => {
        toast.info(`We're processing your sign up to ${bookieDetail.name}.`);
        onDismiss();
      },
      onError: () => {
        setErrorMessage('Something went wrong.');
      },
    }
  );

  const signup: SubmitHandler<FormValues> = async (data) => {
    await signupBookie.mutate(data);
  };

  return (
    <Modal title="Login" onDismiss={onDismiss}>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.imgContainer}>
            <img
              src={bookieDetail.logo}
              alt={bookieDetail.name}
              className={styles.img}
            />
          </div>
          <span className={styles.title}>Sign up to {bookieDetail.name}</span>
        </div>
        <form onSubmit={handleSubmit(signup)}>
          <InputRow
            label="Password"
            errorText={errors.preferredPassword?.message}
          >
            <Input
              id="Password"
              type="password"
              {...register('preferredPassword', { required: 'Required' })}
            />
          </InputRow>
          {errorMessage ? (
            <AlertBox type="error" message={errorMessage} />
          ) : null}
          <FormActions>
            <Button type="submit" disabled={isSubmitting}>
              Sign up
            </Button>
          </FormActions>
        </form>
      </div>
    </Modal>
  );
}
