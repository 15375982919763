import { useContext, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { v4 } from 'uuid';
import Button from '../../../components/Button/Button';
import FormActions from '../../../components/Form/FormActions';
import Input from '../../../components/Form/Input';
import InputRow from '../../../components/Form/InputRow';
import Icon from '../../../components/Icon/Icon';
import Modal from '../../../components/Modal/Modal';
import useAppState from '../../../hooks/useAppState/useAppState';
import AccountContext from '../AccountContext';
import styles from './ActivateCardModal.module.css';

type Props = {
  onDismiss: () => void;
};

type FormValues = {
  activationCode: string;
};

export default function ActivateCardModal({ onDismiss }: Props) {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>();
  const { card } = useContext(AccountContext);
  const { axios } = useAppState();
  const [activated, setActivated] = useState(false);
  const queryClient = useQueryClient();

  const activateCard = useMutation<unknown, unknown, FormValues>(
    async (data) => {
      await axios.post(`/v1/cards/${card?.id}/activate`, {
        tid: v4(),
        activationCode: data.activationCode,
      });
    },
    {
      onSuccess: () => {
        setActivated(true);
        toast.success('Your card has been activated.');
        setTimeout(() => {
          queryClient.refetchQueries('Cards');
        }, 1000);
      },
    }
  );

  const verify: SubmitHandler<FormValues> = async (data) => {
    await activateCard.mutate(data);
  };

  return (
    <Modal title="Request Card" onDismiss={onDismiss}>
      <div className={styles.container}>
        {!activated ? (
          <form onSubmit={handleSubmit(verify)}>
            <p>
              Once you receive your activation letter in the mail, please enter
              the activation code below.
            </p>
            <InputRow
              label="Activation Code"
              errorText={errors.activationCode?.message}
            >
              <Input
                id="Verification Code"
                {...register('activationCode', { required: 'Required' })}
              />
            </InputRow>
            <FormActions>
              <Button type="submit" disabled={isSubmitting}>
                Activate Now
              </Button>
            </FormActions>
          </form>
        ) : (
          <div className={styles.activatedContainer}>
            <Icon name="checkmark" className={styles.checkmark} />
            <h3>Your card has been activated!</h3>
            <FormActions>
              <Button onClick={onDismiss}>Continue</Button>
            </FormActions>
          </div>
        )}
      </div>
    </Modal>
  );
}
