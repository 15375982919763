import { useContext } from 'react';
import AlertBox from '../../../components/AlertBox/AlertBox';
import Box from '../../../components/Box/Box';
import AccountContext from '../AccountContext';
import Deposit from './Deposit';
import styles from './Transfer.module.css';
import Withdraw from './Withdraw';

export default function Transfer() {
  const { myBookies, card } = useContext(AccountContext);

  if (card?.status.toLowerCase() === 'pre_active') {
    return null;
  }

  if (myBookies.length === 0) {
    return (
      <Box>
        <h2>Transfer</h2>
        <AlertBox
          type="warning"
          message="You don't have any linked bookmakers. Link a bookmaker first."
          className={styles.alert}
        />
      </Box>
    );
  }

  return (
    <>
      <Box>
        <h2>Deposit</h2>
        <Deposit />
      </Box>
      <Box>
        <h2>Withdraw</h2>
        <Withdraw />
      </Box>
    </>
  );
}
