import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { v4 } from 'uuid';
import AlertBox from '../../../components/AlertBox/AlertBox';
import Button from '../../../components/Button/Button';
import Checkbox from '../../../components/Form/Checkbox';
import FormActions from '../../../components/Form/FormActions';
import Modal from '../../../components/Modal/Modal';
import useAppState from '../../../hooks/useAppState/useAppState';
import { Provider } from '../../../types/models';
import styles from './RequestCardModal.module.css';

type Props = {
  onDismiss: () => void;
};

export default function RequestCardModal({ onDismiss }: Props) {
  const { currentUser, axios } = useAppState();
  const [agreement, setAgreement] = useState(false);
  const [error, setError] = useState(false);
  const providers = useQuery<Provider[]>('Providers', async () => {
    const response = await axios.get<Provider[]>('/v1/providers');
    return response.data;
  });
  const requestCard = useMutation(
    async () => {
      setError(false);
      await axios.post('/v1/cards', {
        providerId: providers.data?.[0].id,
        tid: v4(),
      });
    },
    {
      onSuccess: () => {
        toast.success(
          'Your Bookies Card is on its way. This should take 5 - 10 working days.'
        );
        onDismiss();
      },
      onError: () => {
        setError(true);
        toast.error('Something went wrong requesting your card.');
      },
    }
  );

  const address = JSON.parse(currentUser?.attributes.address);

  return (
    <Modal title="Request Card" onDismiss={onDismiss}>
      <div className={styles.container}>
        {error ? (
          <AlertBox
            type="error"
            message="Something went wrong requesting your card."
          />
        ) : null}
        <div className={styles.header}>
          <span className={styles.title}>Request Bookies Card</span>
          <span className={styles.subtitle}>
            Please confirm your address details below.
          </span>
        </div>
        <div className={styles.addressContainer}>
          <span className={styles.name}>
            {[
              currentUser?.attributes?.given_name,
              currentUser?.attributes.family_name,
            ].join(' ')}
          </span>
          <span className={styles.address}>
            {[address.address, address.suburb, address.state, address.postcode]
              .filter(Boolean)
              .join(', ')}
          </span>
          <span className={styles.disclaimer}>
            Contact us at{' '}
            <a href="mailto:support@bookiescard.com">support@bookiescard.com</a>{' '}
          </span>
        </div>
        <div>
          <span className={styles.subtitle}>Terms &amp; Conditions</span>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla at
            lorem lacus. Vivamus imperdiet vulputate ligula, at blandit lectus
            feugiat et. Pellentesque ultrices metus ac nunc sollicitudin
            viverra. Nunc ipsum orci, placerat ut efficitur vitae, vestibulum in
            dolor. Duis aliquam elit vitae dolor porta pretium. Ut ultricies
            libero at suscipit faucibus. Aenean et ex condimentum sapien
            tincidunt sodales. Nunc at mauris odio. Vestibulum accumsan ultrices
            ligula, et viverra turpis finibus vel. In nibh velit, fringilla at
            eros vitae, ultricies suscipit elit. Suspendisse ornare eu est et
            efficitur. Curabitur in volutpat nulla, eget euismod turpis. Nam
            venenatis vehicula augue aliquet ultricies. Mauris nibh leo,
            malesuada id sapien et, tincidunt mollis risus. Aenean tristique
            dolor et ligula blandit, et ullamcorper neque iaculis. Ut at sapien
            eget turpis commodo imperdiet.
          </p>
        </div>
        <Checkbox
          id="agree"
          label="I agree to the terms &amp; conditions"
          checked={agreement}
          onChange={() => setAgreement((prev) => !prev)}
        />
        <FormActions>
          <Button theme="secondary" onClick={onDismiss}>
            Back
          </Button>
          <Button
            disabled={!agreement || requestCard.isLoading}
            onClick={() => requestCard.mutate()}
          >
            Request Card
          </Button>
        </FormActions>
      </div>
    </Modal>
  );
}
