import Auth from '@aws-amplify/auth';
import axios from 'axios';
import dayjs from 'dayjs';
import QueryString from 'qs';
import { useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { Link, useHistory } from 'react-router-dom';
import AlertBox from '../../../components/AlertBox/AlertBox';
import Button from '../../../components/Button/Button';
import Checkbox from '../../../components/Form/Checkbox';
import FormActions from '../../../components/Form/FormActions';
import Input from '../../../components/Form/Input';
import InputRow from '../../../components/Form/InputRow';
import Icon from '../../../components/Icon/Icon';
import Modal from '../../../components/Modal/Modal';
import useClickOutside from '../../../hooks/useClickOutside';
import { Harmony, HarmonyAddress } from '../../../types/harmony';
import { SignupForm } from '../../../types/models';
import styles from './SignupModal.module.css';

const harmonyAxios = axios.create({
  baseURL: process.env.REACT_APP_HARMONY_ENV,
  auth: {
    username: process.env.REACT_APP_HARMONY_USERNAME || '',
    password: process.env.REACT_APP_HARMONY_PASSWORD || '',
  },
});

const sourceOfTruth = 'AUPAF';

export default function SignupModal() {
  const history = useHistory();
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm<SignupForm>();
  const [errorMessage, setErrorMessage] = useState('');
  const [fullAddress, setFullAddress] = useState('');
  const [addresses, setAddresses] = useState<HarmonyAddress[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<HarmonyAddress>();
  const [focused, setFocused] = useState(false);
  const [disclaimerAccepted, setDisclaimerAccepted] = useState(false);
  const [registeredUsername, setRegisteredUsername] = useState('');
  const addressRef = useRef<HTMLDivElement>(null);
  useClickOutside(addressRef, () => setFocused(false), focused);

  const getAddress = useMutation<Harmony, unknown, string>(
    async (address) => {
      const response = await harmonyAxios.get('/rest/AU/address', {
        params: {
          fullAddress: address,
          sourceOfTruth,
        },
      });
      return response.data;
    },
    {
      onSuccess: (data) => {
        setAddresses(data.payload);
      },
    }
  );
  const transaction = useMutation(async () => {
    await harmonyAxios.post('/rest/AU/transaction', {
      sourceOfTruth,
    });
  });

  const submitHandler: SubmitHandler<SignupForm> = async (formValues) => {
    try {
      if (!selectedAddress) {
        setError('address', { message: 'Please select a valid address.' });
      } else {
        await Auth.signUp({
          username: formValues.username || '',
          password: formValues.password || '',
          attributes: {
            email: formValues.email,
            given_name: formValues.firstName,
            family_name: formValues.lastName,
            address: JSON.stringify({
              fullAddress: selectedAddress.fullAddress,
              flatNumber: selectedAddress.flatUnitNumber,
              streetNumber: selectedAddress.streetNumber,
              streetName: selectedAddress.streetName,
              streetType: selectedAddress.streetType,
              suburb: selectedAddress.locality,
              state: selectedAddress.state,
              postcode: selectedAddress.postcode,
              country: 'AU',
            }),
            birthdate: dayjs(formValues.dateOfBirth).format('YYYY-MM-DD'),
            gender: 'male', // TODO: hardcoded for now as it is a required field
            phone_number: `+61${formValues.mobile}`,
          },
        });
        setRegisteredUsername(formValues.username || '');
      }
    } catch (error) {
      setErrorMessage('Something went wrong. Please contact support.');
    }
  };

  async function onChangeAddress(event: React.ChangeEvent<HTMLInputElement>) {
    const newValue = event.target.value;
    setFullAddress(newValue);
    setSelectedAddress(undefined);

    if (newValue.length > 3) {
      await getAddress.mutate(newValue);
    }
  }

  return (
    <Modal title="Sign up" onDismiss={() => history.push({ search: '' })}>
      <div className={styles.container}>
        {!registeredUsername ? (
          <>
            <Icon name="logo" className={styles.logo} />
            <form onSubmit={handleSubmit(submitHandler)}>
              <InputRow
                label="Name"
                errorText={
                  errors.firstName?.message || errors.lastName?.message
                }
              >
                <Input
                  id="Name"
                  placeholder="Enter your first name"
                  autoComplete="given-name"
                  autoFocus
                  {...register('firstName', { required: 'Required' })}
                />
                <Input
                  placeholder="Enter your last name"
                  autoComplete="family-name"
                  {...register('lastName', { required: 'Required' })}
                />
              </InputRow>
              <InputRow
                label="Date of birth"
                errorText={errors.dateOfBirth?.message}
              >
                <Input
                  id="Date of birth"
                  placeholder="Enter your date of birth"
                  type="date"
                  autoComplete="bday"
                  {...register('dateOfBirth', { required: 'Required' })}
                />
              </InputRow>
              <InputRow label="Mobile" errorText={errors.mobile?.message}>
                <Input
                  id="Mobile"
                  placeholder="Enter your mobile"
                  type="number"
                  prefix="+61"
                  min={0}
                  autoComplete="tel-local"
                  {...register('mobile', {
                    required: 'Required',
                    pattern: {
                      value: /^4[0-9]{8}$/,
                      message:
                        'Mobile number must not include the country code and must be a valid length.',
                    },
                  })}
                />
              </InputRow>
              <AlertBox
                type="warning"
                message="Your mobile number must be the same number used with available bookmakers to link them to Bookie Card."
              />
              <InputRow label="Email" errorText={errors.email?.message}>
                <Input
                  id="Email"
                  placeholder="Enter your email address"
                  type="email"
                  autoComplete="email"
                  {...register('email', { required: 'Required' })}
                />
              </InputRow>
              <InputRow
                label="Address"
                errorText={errors.address?.message}
                ref={addressRef}
              >
                <Input
                  id="Address"
                  placeholder="Enter your address"
                  value={fullAddress}
                  onChange={onChangeAddress}
                  onFocus={() => setFocused(true)}
                  autoComplete="off"
                />
                {focused && addresses.length > 0 ? (
                  <div className={styles.selections}>
                    {addresses.map((address) => (
                      <button
                        type="button"
                        key={address.id}
                        className={styles.selection}
                        onClick={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                          setSelectedAddress(address);
                          clearErrors('address');
                          setFullAddress(address.fullAddress);
                          setFocused(false);
                          transaction.mutate();
                        }}
                      >
                        {address.fullAddress}
                      </button>
                    ))}
                  </div>
                ) : null}
              </InputRow>
              <InputRow label="Username" errorText={errors.username?.message}>
                <Input
                  id="Username"
                  placeholder="Enter your username"
                  autoComplete="username"
                  {...register('username', {
                    required: 'Required',
                    pattern: {
                      value:
                        // eslint-disable-next-line
                        /^\w+$/,
                      message:
                        'Username cannot contain any special characters or symbols',
                    },
                  })}
                />
              </InputRow>
              <InputRow
                label="Password"
                errorText={errors.password?.message}
                hintText="Must have 8 characters, including upper & lower case, numbers & special characters"
              >
                <Input
                  id="Password"
                  placeholder="Enter your password"
                  type="password"
                  autoComplete="new-password"
                  {...register('password', {
                    required: 'Required',
                    pattern: {
                      value:
                        // eslint-disable-next-line
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-“!@#%&\/,><\’:;|_~`])\S{8,99}$/,
                      message:
                        'Password must have 8 characters, including upper & lower case, numbers & special characters',
                    },
                  })}
                />
              </InputRow>
              <Checkbox
                id="disclaimer"
                label={
                  <span>
                    By selecting this checkbox, I request to receive a Bookies
                    Card and declare that all information provided in connection
                    with my application for a Bookies Card is true and correct.
                    I am aware that the{' '}
                    <a
                      href="https://bookiescard.com/docs/20211021%20-%20Bookies%20Card%20Mastercard%20-%20PDS.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Bookies Card Product Disclosure Statement
                    </a>{' '}
                    and{' '}
                    <a
                      href="https://bookiescard.com/docs/20211021%20-%20Bookies%20Card%20-%20FSG.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Financial Services Guide
                    </a>{' '}
                    are available online, and I confirm that I have read and
                    agree to be bound by the terms of the{' '}
                    <a
                      href="https://bookiescard.com/docs/20211021%20-%20Bookies%20Card%20Mastercard%20-%20PDS.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Bookies Card Product Disclosure Statement
                    </a>
                    . I also confirm my authority to receive disclosures about
                    my Bookies Card online.
                  </span>
                }
                checked={disclaimerAccepted}
                onChange={(event) =>
                  setDisclaimerAccepted(event.target.checked)
                }
              />
              {errorMessage ? (
                <AlertBox type="error" message={errorMessage} />
              ) : null}

              <FormActions>
                <Button
                  type="submit"
                  disabled={!disclaimerAccepted || isSubmitting}
                >
                  Sign up
                </Button>
              </FormActions>
            </form>
            <span className={styles.disclaimer}>
              Have an account?{' '}
              <Link to={{ search: 'action=login' }}>Login</Link> or{' '}
              <Link to={{ search: 'action=reset' }}>Reset your password</Link>
            </span>
          </>
        ) : (
          <div className={styles.registeredContainer}>
            <Icon name="checkmark" className={styles.checkmark} />
            <h3>Your account has been created!</h3>
            <p>
              Please check your email and click on &quot;Verify Email&quot;
              before logging in.
            </p>
            <FormActions>
              <Button
                onClick={() =>
                  history.push({
                    search: QueryString.stringify({ action: 'login' }),
                  })
                }
              >
                Login
              </Button>
            </FormActions>
          </div>
        )}
      </div>
    </Modal>
  );
}
