import { useContext } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { v4 } from 'uuid';
import Button from '../../../../components/Button/Button';
import FormActions from '../../../../components/Form/FormActions';
import Modal from '../../../../components/Modal/Modal';
import useAppState from '../../../../hooks/useAppState/useAppState';
import AccountContext from '../../AccountContext';
import styles from './LostCardModal.module.css';

export default function LostCardModal({
  onDismiss,
}: {
  onDismiss: () => void;
}) {
  const { card } = useContext(AccountContext);
  const { axios } = useAppState();

  const lostCard = useMutation(
    async () => {
      await axios.put(`/v1/cards/${card?.id}/status`, {
        tid: v4(),
        status: 'lost_or_stolen',
      });
    },
    {
      onSuccess: () => {
        toast.success(
          'Card Deactivated. A confirmation email has been sent with instructions on what to do next.'
        );
      },
    }
  );

  async function reportLost() {
    await lostCard.mutate();
  }

  return (
    <Modal title="Deactivate Bookies Card" onDismiss={onDismiss}>
      <div className={styles.container}>
        <div className={styles.header}>
          <span className={styles.subtitle}>
            Are you sure you want to deactivate your Bookies Card?
          </span>
        </div>
        <FormActions>
          <Button
            theme="secondary"
            disabled={lostCard.isLoading}
            onClick={onDismiss}
          >
            Cancel
          </Button>
          <Button onClick={reportLost} disabled={lostCard.isLoading}>
            Deactivate Card
          </Button>
        </FormActions>
      </div>
    </Modal>
  );
}
