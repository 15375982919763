import { useContext } from 'react';
import Box from '../../../components/Box/Box';
import Card from '../../../components/Card/Card';
import bookieDetail from '../../../utils/bookieDetail';
import format from '../../../utils/format';
import AccountContext from '../AccountContext';
import styles from './MyBookmakers.module.css';

export default function MyBookmakers() {
  const { myBookies, balance } = useContext(AccountContext);

  if (myBookies?.length === 0) return null;

  return (
    <Box>
      <h2>My Bookmakers</h2>
      <div className={styles.list}>
        {myBookies.map((bookie) => {
          const bookieMap = bookieDetail[bookie.bookieId];
          const actualBalance =
            balance.find((b) => b.bookieId === bookie.bookieId)?.balance || 0;
          const withdrawableBalance =
            balance.find((b) => b.bookieId === bookie.bookieId)
              ?.withdrawableBalance || 0;

          return (
            <Card key={bookie.id}>
              <div className={styles.bookieContainer}>
                <div>
                  <div className={styles.bookie}>
                    <img src={bookieMap.logo} alt={bookieMap.name} />
                    <span className={styles.name}>{bookieMap.name}</span>
                  </div>
                  <div>
                    <span className={styles.label}>Balance:</span>
                    <span className={styles.value}>
                      {format.currency(actualBalance)}
                    </span>
                  </div>
                  <div>
                    <span className={styles.label}>Withdrawable:</span>
                    <span className={styles.value}>
                      {format.currency(withdrawableBalance)}
                    </span>
                  </div>
                </div>
                <div>
                  {bookieMap.appstore ? (
                    <a
                      href={bookieMap.appstore}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.button}
                    >
                      <img src="/appstore.svg" alt="App Store" />
                    </a>
                  ) : null}
                  {bookieMap.googleplay ? (
                    <a
                      href={bookieMap.googleplay}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.button}
                    >
                      <img src="/google-play.png" alt="Google Play" />
                    </a>
                  ) : null}
                </div>
              </div>
            </Card>
          );
        })}
      </div>
    </Box>
  );
}
