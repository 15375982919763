import { CognitoUser } from '@aws-amplify/auth';
import axios, { AxiosInstance } from 'axios';
import { createContext, Dispatch, SetStateAction } from 'react';

const AppStateContext = createContext<{
  currentUser: (CognitoUser & { attributes?: any }) | undefined;
  setCurrentUser: Dispatch<SetStateAction<CognitoUser | undefined>>;
  checkAuth: () => void;
  axios: AxiosInstance;
  showSettings: boolean;
  setShowSettings: Dispatch<SetStateAction<boolean>>;
}>({
  currentUser: undefined,
  setCurrentUser: () => {},
  checkAuth: () => {},
  axios: axios.create(),
  showSettings: false,
  setShowSettings: () => false,
});

export default AppStateContext;
