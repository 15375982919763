import { useMutation, useQuery } from 'react-query';
import { useContext, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { v4 } from 'uuid';
import { toast } from 'react-toastify';
import useAppState from '../../../hooks/useAppState/useAppState';
import AccountContext from '../AccountContext';
import Loading from '../../../components/Loading/Loading';
import styles from './VerifyModal.module.css';

export default function GreenId({ onDismiss }: { onDismiss: () => void }) {
  const { axios } = useAppState();
  const { user } = useContext(AccountContext);
  const [loading, setLoading] = useState(true);

  const getVerificationResult = useMutation<unknown, unknown, string>(
    async (verificationId) => {
      const response = await axios.post(`/v1/users/${user?.id}/verify`, {
        tid: v4(),
        operation: 'getVerificationResult',
        verificationId,
      });
      return response.data;
    },
    {
      onSuccess: () => {
        toast.success('You have been successfully verified.');
        onDismiss();
      },
      onError: () => {
        toast.error(
          'Something went wrong with your verification. Please try again.'
        );
        onDismiss();
      },
    }
  );
  const getVerificationToken = useMutation<
    { data: string; verificationId: string },
    unknown,
    string
  >(
    async (verificationId) => {
      const response = await axios.post(`/v1/users/${user?.id}/verify`, {
        tid: v4(),
        operation: 'getVerificationToken',
        verificationId,
      });
      return {
        ...response.data,
        verificationId,
      };
    },
    {
      onSuccess: (data) => {
        // @ts-ignore
        window.greenidUI.setup({
          environment: process.env.REACT_APP_GREENID_ENV,
          frameId: 'greenid-div',
          sessionCompleteCallback: async (d: unknown, success: unknown) => {
            if (success === 'VERIFIED') {
              await getVerificationResult.mutate(data.verificationId);
            }
          },
        });

        // @ts-ignore
        window.greenidUI.show(
          process.env.REACT_APP_GREENID_ACCOUNTID,
          process.env.REACT_APP_GREENID_API_CODE,
          data.data
        );

        setLoading(false);
      },
    }
  );
  useQuery<{ verificationId: string; verificationStatus: string }, unknown>(
    ['Verification'],
    async () => {
      const response = await axios.post(`/v1/users/${user?.id}/verify`, {
        tid: v4(),
        operation: 'registerVerification',
      });
      return response.data;
    },
    {
      onSuccess: async (data) => {
        await getVerificationToken.mutate(data.verificationId);
      },
    }
  );

  return (
    <>
      <Helmet>
        <script
          async
          src="https://simpleui-test-au.vixverify.com/df/javascripts/greenidConfig.js"
        />
        <script
          async
          src="https://simpleui-test-au.vixverify.com/df/javascripts/greenidui.min.js"
        />
        <link
          rel="stylesheet"
          href="https://simpleui-test-au.vixverify.com/df/assets/stylesheets/greenid.css"
        />
      </Helmet>
      <div className={styles.container}>
        <div id="greenid-div" />
        {loading ? <Loading /> : null}
      </div>
    </>
  );
}
