import Button from '../../../../components/Button/Button';
import FormActions from '../../../../components/Form/FormActions';
import Modal from '../../../../components/Modal/Modal';
import styles from './ContactUsModal.module.css';

export default function ContactUsModal({
  onDismiss,
}: {
  onDismiss: () => void;
}) {
  return (
    <Modal title="Contact Us" onDismiss={onDismiss}>
      <div className={styles.container}>
        <div className={styles.header}>
          <span className={styles.subtitle}>
            Contact us at{' '}
            <a href="mailto:support@bookiescard.com">support@bookiescard.com</a>{' '}
            <br />
          </span>
        </div>
        <FormActions>
          <Button theme="secondary" onClick={onDismiss}>
            Done
          </Button>
        </FormActions>
      </div>
    </Modal>
  );
}
