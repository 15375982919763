import { useContext, useState } from 'react';
import { useQuery } from 'react-query';
import Box from '../../../components/Box/Box';
import Card from '../../../components/Card/Card';
import useAppState from '../../../hooks/useAppState/useAppState';
import bookieDetail from '../../../utils/bookieDetail';
import AccountContext from '../AccountContext';
import styles from './AvailableBookmakers.module.css';
import BookmakerLink from './BookmakerLink/BookmakerLink';
import BookmakerSignup from './BookmakerSignup/BookmakerSignup';

type Bookie = {
  id: string;
  name: string;
  providerIds: string[];
};

export default function AvailableBookmakers({
  onVerify,
}: {
  onVerify: () => void;
}) {
  const { axios } = useAppState();
  const [linkForBookie, setLinkForBookie] = useState<Bookie>();
  const [signupForBookie, setSignupForBookie] = useState<Bookie>();
  const { myBookies, verified } = useContext(AccountContext);
  const bookies = useQuery<Bookie[]>('Bookies', async () => {
    const response = await axios.get('/v1/bookies');
    return response.data;
  });
  const availableBookies = bookies.data?.filter(
    (bookie) => !myBookies.map((b) => b.bookieId).includes(bookie.id)
  );

  if (bookies.isLoading || availableBookies?.length === 0) return null;

  return (
    <Box>
      <h2>Sign up / Link Bookmakers</h2>
      <div className={styles.list}>
        {availableBookies?.map((bookie) => {
          const bookieMap = bookieDetail[bookie.id];

          return (
            <Card key={bookie.id}>
              <div className={styles.bookie}>
                <div className={styles.bookieDetail}>
                  <img src={bookieMap.logo} alt={bookieMap.name} />
                  <span className={styles.name}>{bookieMap.name}</span>
                </div>
                <div className={styles.actions}>
                  {verified ? (
                    <>
                      <button
                        type="button"
                        className={styles.button}
                        onClick={() => setLinkForBookie(bookie)}
                      >
                        Link
                      </button>
                      <button
                        type="button"
                        className={styles.button}
                        onClick={() => setSignupForBookie(bookie)}
                      >
                        Sign up
                      </button>
                    </>
                  ) : (
                    <button
                      type="button"
                      className={styles.button}
                      onClick={onVerify}
                    >
                      Verify
                    </button>
                  )}
                </div>
              </div>
            </Card>
          );
        })}
      </div>
      {linkForBookie ? (
        <BookmakerLink
          bookieId={linkForBookie.id}
          bookieDetail={bookieDetail[linkForBookie.id]}
          onDismiss={() => setLinkForBookie(undefined)}
        />
      ) : null}
      {signupForBookie ? (
        <BookmakerSignup
          bookieId={signupForBookie.id}
          bookieDetail={bookieDetail[signupForBookie.id]}
          onDismiss={() => setSignupForBookie(undefined)}
        />
      ) : null}
    </Box>
  );
}
