import { useState } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { v4 } from 'uuid';
import AlertBox from '../../../../components/AlertBox/AlertBox';
import Button from '../../../../components/Button/Button';
import FormActions from '../../../../components/Form/FormActions';
import Modal from '../../../../components/Modal/Modal';
import useAppState from '../../../../hooks/useAppState/useAppState';
import { BookieDetail } from '../../../../types/models';
import styles from './BookmakerLink.module.css';

type Props = {
  bookieId: string;
  bookieDetail: BookieDetail;
  onDismiss: () => void;
};

export default function BookmakerLogin({
  bookieId,
  bookieDetail,
  onDismiss,
}: Props) {
  const { axios } = useAppState();
  const [errorMessage, setErrorMessage] = useState('');
  const loginBookie = useMutation<unknown, unknown, unknown>(
    async () => {
      await axios.post('/v1/userbookies', {
        action: 'link',
        bookieId,
        tid: v4(),
      });
    },
    {
      onSuccess: () => {
        toast.info(`We're processing your login to ${bookieDetail.name}.`);
        onDismiss();
      },
      onError: () => {
        setErrorMessage('Something went wrong.');
      },
    }
  );

  return (
    <Modal title="Login" onDismiss={onDismiss}>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.imgContainer}>
            <img
              src={bookieDetail.logo}
              alt={bookieDetail.name}
              className={styles.img}
            />
          </div>
          <span className={styles.title}>Link with {bookieDetail.name}</span>
        </div>
        {errorMessage ? <AlertBox type="error" message={errorMessage} /> : null}
        <p>
          If you have an account already with {bookieDetail.name}, you can link
          it with Bookies Card to deposit and withdraw funds on.
        </p>
        <FormActions>
          <Button onClick={loginBookie.mutate} disabled={loginBookie.isLoading}>
            Link Account
          </Button>
        </FormActions>
      </div>
    </Modal>
  );
}
