import Auth, { CognitoUser } from '@aws-amplify/auth';
import axios from 'axios';
import QueryString from 'qs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import Loading from '../../components/Loading/Loading';
import AppStateContext from './AppStateContext';

export default function AppStateProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [currentUser, setCurrentUser] = useState<CognitoUser>();
  const [ready, setReady] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const queryClient = useQueryClient();
  const axiosInstance = useMemo(() => {
    const instance = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
    });

    instance.interceptors.request.use(async (config) => {
      const session = await Auth.currentSession();
      const idToken = session.getIdToken();
      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${idToken.getJwtToken()}`,
        },
        url: `${config.url}${QueryString.stringify(
          { username: idToken.payload['cognito:username'] },
          { addQueryPrefix: true }
        )}`,
      };
    });

    return instance;
  }, []);

  const checkAuth = useCallback(async () => {
    try {
      const user: CognitoUser = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });
      setCurrentUser(user);
      setReady(true);
    } catch (error) {
      setReady(true);
    }
  }, []);

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  if (!ready) return <Loading />;

  return (
    <AppStateContext.Provider
      value={{
        currentUser,
        setCurrentUser: (user) => {
          queryClient.clear();
          setCurrentUser(user);
        },
        checkAuth,
        axios: axiosInstance,
        showSettings,
        setShowSettings,
      }}
    >
      {children}
    </AppStateContext.Provider>
  );
}
