import { createContext } from 'react';
import {
  BookieCard,
  User,
  UserBookie,
  UserBookieBalance,
} from '../../types/models';

const AccountContext = createContext<{
  card?: BookieCard;
  myBookies: UserBookie[];
  user?: User;
  verified: boolean;
  balance: UserBookieBalance[];
}>({
  myBookies: [],
  verified: false,
  balance: [],
});

export default AccountContext;
