import { useEffect, useState } from 'react';
import Modal from '../../../components/Modal/Modal';
import useAppState from '../../../hooks/useAppState/useAppState';
import GreenId from './GreenId';
import MobileVerification from './MobileVerification';

export default function VerifyModal({ onDismiss }: { onDismiss: () => void }) {
  const { currentUser } = useAppState();
  const [ready, setReady] = useState(false);
  const [mobileVerified, setMobileVerified] = useState(false);

  useEffect(() => {
    setReady(true);
    setMobileVerified(currentUser?.attributes.phone_number_verified);
  }, [currentUser]);

  if (!ready) return null;

  return (
    <Modal
      title={
        mobileVerified ? 'Verify your identity' : 'Verify your mobile number'
      }
      onDismiss={onDismiss}
    >
      {mobileVerified ? (
        <GreenId onDismiss={onDismiss} />
      ) : (
        <MobileVerification setMobileVerified={setMobileVerified} />
      )}
    </Modal>
  );
}
