import dayjs from 'dayjs';
import { useContext } from 'react';
import AlertBox from '../../../../components/AlertBox/AlertBox';
import Modal from '../../../../components/Modal/Modal';
import useAppState from '../../../../hooks/useAppState/useAppState';
import AccountContext from '../../AccountContext';
import styles from './PersonalDetailsModal.module.css';

export default function PersonalDetailsModal({
  onDismiss,
}: {
  onDismiss: () => void;
}) {
  const { currentUser } = useAppState();
  const { verified } = useContext(AccountContext);
  const address = JSON.parse(currentUser?.attributes.address);

  return (
    <Modal title="Personal Details" onDismiss={onDismiss}>
      <div className={styles.container}>
        <div className={styles.header}>
          <span className={styles.title}>Personal Details</span>
        </div>
        {verified ? (
          <AlertBox
            type="success"
            message="Verified"
            className={styles.alert}
          />
        ) : null}
        <div className={styles.content}>
          <div className={styles.section}>
            <span className={styles.label}>Name</span>
            <span className={styles.value}>
              {[
                currentUser?.attributes?.given_name,
                currentUser?.attributes.family_name,
              ].join(' ')}
            </span>
          </div>
          <div className={styles.section}>
            <span className={styles.label}>Date of Birth</span>
            <span className={styles.value}>
              {dayjs(currentUser?.attributes.birthdate).format('DD/MM/YYYY')}
            </span>
          </div>
          <div className={styles.section}>
            <span className={styles.label}>Mobile</span>
            <span className={styles.value}>
              {currentUser?.attributes.phone_number}
            </span>
          </div>
          <div className={styles.section}>
            <span className={styles.label}>Address</span>
            <span className={styles.value}>
              {[
                address.address,
                address.suburb,
                address.state,
                address.postcode,
              ]
                .filter(Boolean)
                .join(', ')}
            </span>
          </div>
          <div className={styles.section}>
            <span className={styles.label}>Username</span>
            <span className={styles.value}>{currentUser?.getUsername()}</span>
          </div>
        </div>
        <span className={styles.disclaimer}>
          Contact us at{' '}
          <a href="mailto:support@bookiescard.com">support@bookiescard.com</a>{' '}
        </span>
      </div>
    </Modal>
  );
}
